@import 'erpcore/assets/scss/main';

.session-view {
    @include breakpoint($to: tablet) {
        flex-direction: column;
    }

    &__prospect-single {
        display: block;
        width: 100%;
        margin-bottom: $spacing + 4px;

        &:first-child {
            padding-top: $spacing;
        }

        .button {
            position: relative;
            top: -3px;
            margin-right: $spacing;
        }
    }

    &__header {
        margin-bottom: $spacing * 2;
    }

    &__notes {
        overflow-y: auto;
        max-height: 400px;
    }

    &__label {
        display: block;
        font: $font-label;
        margin-top: $spacing;
    }
    &__value {
        display: block;
        margin-bottom: $spacing * 2;

        &-chip {
            background: rgba(getColor(denim), 0.1);
            color: rgba(getColor(denim), 1);
            border-radius: 5px;
            display: inline-block;
            margin: 2px $spacing 2px 0;
            padding: 2px $spacing;
        }

        &--project {
            font: $font-4;
            margin-top: $spacing;

            svg {
                fill: rgba(getColor(denim), 1);
            }
        }
    }

    &__items {
        margin-bottom: $spacing * 4;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__columns {
        width: 100%;
        display: flex;
    }

    &__column {
        text-align: center;
        width: 100%;

        &:last-child {
            text-align: right;
        }

        &:first-child {
            text-align: left;
        }
    }

    &__icon {
        fill: rgba(getColor(apple), 1);

        &--denim {
            fill: rgba(getColor(denim), 1);
        }
        &--tomato {
            fill: rgba(getColor(tomato), 1);
        }
    }

    &__prospect {
        position: relative;
        background: rgba(getColor(white), 1);
        border: 1px solid rgba(getColor(light-grey), 1);
        margin-bottom: $spacing * 3;
        padding: $spacing * 2 $spacing * 2 $spacing * 2 $spacing * 2;

        &:last-child {
            margin-bottom: $spacing * 2;
        }

        &-questions {
            overflow-y: auto;
            max-height: 400px;
            margin-bottom: $spacing * 2;
            padding-right: $spacing;
        }

        &-document-form {
            margin-top: $spacing * 2;

            &-label {
                display: block;
                margin-bottom: $spacing;
            }
        }

        &-attended {
            float: right;
            margin-right: 40px;
            margin-top: 5px;
        }

        &-line {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid rgba(getColor(light-grey), 1);
            padding: $spacing 0;

            &:last-child {
                border-bottom: none;
            }

            &-links {
                width: 55%;
                text-align: right;
            }
            &-label {
                display: flex;
                width: 45%;
                .svg {
                    margin-left: $spacing;
                }
            }
            &-stats {
                display: flex;
                margin-top: $spacing;
                width: 100%;
                .svg {
                    margin-right: $spacing;
                }
            }
        }

        .collapse {
            padding-right: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__note {
        margin-bottom: $spacing * 2;
        &:last-child {
            margin-bottom: 0;
        }

        &-info {
            font: $font-label;
            color: rgba(getColor(mid-grey), 1);
        }
    }

    &__info-box {
        display: flex;
        justify-content: space-around;
        @include breakpoint($to: tablet) {
            flex-wrap: wrap;
            margin-bottom: -$spacing * 2;
        }
    }

    &__data {
        display: flex;
        flex-direction: column;
        flex: 1 1 calc(100% / 6);
        width: calc(100% / 6);

        @include breakpoint($to: tablet) {
            flex: 1 1 calc(100% / 3);
            padding-bottom: $spacing * 2;
        }
        @include breakpoint($to: mobile) {
            flex: 1 1 50%;
        }

        &-header {
            font: $font-label;
        }

        &-body {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 0;
        }
    }

    &__content {
        display: flex;
        width: 100%;
        padding-bottom: $spacing * 2;

        @include breakpoint($to: tablet) {
            flex-direction: column;
        }
    }

    &__main {
        flex: 3;
        margin-right: $spacing * 3;

        &:last-child {
            margin-right: 0;
        }

        @include breakpoint($to: tablet) {
            margin-right: 0;
            margin-bottom: $spacing * 3;
        }
    }
    &__sidebar {
        flex: 2;

        .collapse {
            padding-right: 0;
            margin-bottom: $spacing * 3;

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }

    &__guest {
        margin: $spacing 0;

        &-email {
            color: rgba(getColor(mid-grey), 1);
        }

        &:last-child {
            margin-bottom: 0;
        }

        strong {
            margin-right: $spacing;
        }

        &-list {
            margin-bottom: $spacing * 3;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
