@import 'erpcore/assets/scss/main';

.tabs-navigation {
    width: 100%;
    font: $font-4;
    $root: &;
    // margin-bottom: 20px;

    &__links {
        // display: flex;
        list-style: none;
        padding: 0;
    }

    &:hover {
        #{$root}__link {
            box-shadow: 0px 0px 3px rgba(0,0,0,0.1);
        }
    }

    &__link {
        color: rgba(getColor(dark-grey), 1);
        padding: $spacing * 2;
        white-space: nowrap;

        &--active {
            color: rgba(getColor(denim), 1);
        }

        &:hover {
            text-decoration: none;
            color: rgba(getColor(denim), 0.7);
            background: rgba(getColor(light-grey), 0.7);
        }
    }
}