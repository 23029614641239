@import 'erpcore/assets/scss/main';

.progress-bar {
    width: 100%;
    height: 4px;
    background: rgba(getColor(light-grey), 1);
    min-width: 100px;
    border-radius: 3px;
    overflow: hidden;
    margin: $spacing 0;

    &__line {
        height: 4px;
        background: rgba(getColor(denim), 1);
        transition: getTransition('slide-slow');
    }
}
