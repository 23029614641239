@import 'erpcore/assets/scss/main';

.tabs-navigation {
    position: relative;
    width: 100%;
    font: $font-4;
    padding: $spacing * 2 0;
    margin-bottom: 1rem;

    &__links, &__sublinks {
        list-style: none;
        padding: 0;
    }

    ul {
        margin-bottom: 15px;
    }

    &__links {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(30px, max-content));
        @media (max-width: 768px) {
            & {
                grid-template-columns: repeat(auto-fit, minmax(80px, 120px));
                gap: 32px;
            }
        }
    }

    &__sublinks {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(30px, max-content));
        gap: 24px;
        font: $font-list;
        background: rgba(getColor(light-grey), 1);
        padding: $spacing * 2;
        margin: 0;
        
        @media (max-width: 768px) {
            &--layout-without-deck {
                grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
                gap: 24px;
            }
        }

        @media (max-width: 1250px) {
            &--layout-with-deck {
                grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
                gap: 24px;
            }
        }
    }

    &__link, &__sublink {
        color: rgba(getColor(dark-grey), 1);
        white-space: nowrap;

        &--active {
            background: rgba(getColor(light-grey), 1);
            color: rgba(getColor(denim), 1);
            font-weight: 700;

            @media (max-width: 768px) {
                & {
                    background: transparent;
                }
            }
        }

        &:hover {
            text-decoration: none;
            color: rgba(getColor(denim), 0.7);
        }
    }

    &__link {
        &--active {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }

    &__sublinks {
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        @media (max-width: 768px) {
            & {
                border-top-left-radius: 5px;
            }
        }
    }
}