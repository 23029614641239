@import 'erpcore/assets/scss/main';

.tabs {
    $this: &;
    width: 100%;
    font: $font-4;
    display: block;
    color: rgba(getColor(black), 1);
    padding: 0px 0px ($spacing * 4) 0px;

    &__list {
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        list-style-type: none;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;
        overflow-x: auto;
        overflow-y: hidden;

        &-item {
            padding: 0px;
        }
    }

    &__dropdown {
        @include breakpoint($to: tablet) {
            display: flex;
            white-space: nowrap;
        }
    }

    &__dropdown-list {
        padding: 0;
        list-style-type: none;
        display: none;

        @include breakpoint($from: tablet) {
            position: absolute;
            z-index: 9;
            background: rgba(getColor(white), 1);
            box-shadow: 0 2px 6px 0 rgba(getColor(dark-grey), 0.4);
        }

        #{$this}__dropdown:hover & {
            @include breakpoint($from: tablet) {
                display: block;
            }
            @include breakpoint($to: tablet) {
                display: flex;
            }
        }

        &-item {
            padding: 0px;

            @include breakpoint($to: tablet) {
                display: inline-block;
            }
        }
    }
    &__dropdown-link {
        display: block;
        width: 100%;
        text-decoration: none;
        color: rgba(getColor(dark-grey), 1);
        padding: 0px ($spacing * 2);
        white-space: nowrap;
        font: $font-body;

        @include breakpoint($from: tablet) {
            padding: 6px $spacing * 2;
            border-bottom: 1px solid rgba(getColor(light-grey), 1);
        }

        &--active {
            color: rgba(getColor(denim), 1);
        }

        &:hover {
            text-decoration: none;
            color: rgba(getColor(denim), 0.7);
        }
    }

    &__dropdown-label {
        margin-bottom: $spacing;

        &-icon {
            width: $spacing;

            @include breakpoint($to: tablet) {
                transform: rotate(-90deg);
            }

            #{$this}__dropdown:hover & {
                fill: rgba(getColor(denim), 0.7);
            }
        }

        #{$this}__dropdown:hover & {
            text-decoration: none;
            color: rgba(getColor(denim), 0.7);
        }
    }

    &__dropdown-label,
    &__link {
        display: block;
        position: relative;
        width: 100%;
        text-decoration: none;
        color: rgba(getColor(dark-grey), 1);
        padding: 0px ($spacing * 2);
        white-space: nowrap;

        #{$this}__list-item:first-child & {
            padding-left: 0px;
        }

        &:hover {
            text-decoration: none;
            color: rgba(getColor(denim), 0.7);
        }

        &--active {
            color: rgba(getColor(denim), 1);
            cursor: default;

            &:hover {
                color: rgba(getColor(denim), 1);
            }
        }
    }
}
