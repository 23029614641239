@import 'erpcore/assets/scss/main';

.amount {
    display: flex;
    flex-direction: column;
    border: 2px solid rgba(var(--lightGrey), 1);

    & > div {
        margin: 10px 8px !important;
        width: calc(100% - 16px);

        & > button > h3 {
            font-weight: 400;
        }
    }
}
