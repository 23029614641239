@import 'erpcore/assets/scss/main';

.number-range {
    .input__field {
        display: flex;
        align-items: center;

        input {
            height: 80%;
            border: 1px solid rgba(getColor(light-grey), 1);
            padding-left: $spacing;
            max-width: 130px;

            &::placeholder {
                font: $font-label;
            }
        }

        @include breakpoint(small) {
            flex-direction: column;
            height: 100px;
            align-items: flex-start;
            padding-right: $spacing;
            justify-content: center;

            input {
                max-width: 100%;
                width: 100%;
                height: 28px;
            }
        }

        & > div {
            margin: 0 $spacing;
        }
    }
}
