@import 'erpcore/assets/scss/main';

.react-select {
    $this: &;

    &--is-disabled {
        pointer-events: none;

        #{$this}__control {
            background-color: rgba(getColor(light-grey), 1);
            border: 2px solid rgba(getColor(light-grey), 1);
        }

        #{$this}__indicators {
            background-color: rgba(getColor(light-grey), 1);
        }

        & + .input__label {
            background: transparent;
        }
    }

    &--loading {
        #{$this}__single-value {
            background: rgba(getColor(light-grey), 1);
            color: rgba(getColor(light-grey), 1);
            padding: 0 $spacing;
            margin-top: $spacing;
            border-radius: 3px;
            overflow: hidden;
            display: inline-block;
        }
    }

    &--multi {
        position: relative;
    }

    &__loader {
        position: absolute;
        right: $spacing * 5;
        top: $spacing * 2;
        z-index: 9;
        background: rgba(getColor(white), 1);

        &--disabled {
            background-color: rgba(getColor(light-grey), 1);
        }

        #{$this}--action ~ & {
            right: $spacing * 12;
        }
    }

    &__loader--disabled {
        background-color: rgba(getColor(light-grey), 1);
    }

    &__control {
        margin: 0;
        z-index: 2;
        width: 100%;
        height: 40px;
        resize: none;
        display: block;
        font: $font-form;
        padding: 0 $spacing 0 ($spacing * 2);
        cursor: pointer;
        border-radius: 3px;
        position: relative;
        font-weight: normal;
        -webkit-appearance: none;
        color: rgba(getColor(black), 1);
        background-color: rgba(getColor(white), 1);
        border: 2px solid rgba(getColor(light-grey), 1);

        #{$this}--multi & {
            min-height: 40px;
            height: auto;
        }

        .input--error & {
            border: 2px solid rgba(getColor(tomato), 1);
        }
    }

    &__control--menu-is-open {
        border: 2px solid rgba(getColor(mid-grey), 1);

        #{$this}__dropdown-indicator {
            transform: rotate(180deg);
        }
    }

    &__input {
        top: 0;
        bottom: 0;
        left: ($spacing * 2);
        right: ($spacing * 4);
        position: absolute;

        #{$this}--multi & {
            position: static;
            min-height: 36px;
            height: 100%;
        }

        input {
            height: 100%;
            display: block;
        }
    }

    &__value-container {
        display: block;

        &--is-multi {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: stretch;
            height: 100%;
            padding-right: 50px;
        }
    }

    &__single-value {
        display: block;
        padding: $spacing 46px $spacing 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &__multi-value {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 20px;
        max-width: 100%;
        margin: #{$spacing + 1px} $spacing 0 0; // no bottom margin
        padding: 0 $spacing * 4 0 $spacing * 2;
        font: $font-label;
        color: rgba(getColor(light-grey), 1);
        background: rgba(getColor(mid-grey), 1);

        &:last-child {
            margin-right: 0;
        }

        &__label {
            display: block;
            max-width: 200px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        &__remove {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            transition: getTransition();

            &:hover {
                background: getColor(dark-grey-hover);
            }

            svg {
                display: block;
                width: $spacing * 2;
                height: $spacing * 2;
                fill: rgba(getColor(light-grey), 1);
            }
        }
    }

    &__indicators {
        display: flex;
        top: 0;
        right: $spacing;
        bottom: 2px;
        position: absolute;
        transform: rotate(0deg);
        z-index: 2;
        background: rgba(getColor(white), 1);
        // padding-left: $spacing;
    }

    &__dropdown-indicator,
    &__clear-indicator {
        height: 100%;
        padding: 0 $spacing;
        flex-wrap: nowrap;
        align-items: center;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        text-align: center;
        transition: getTransition();

        #{$this}--multi & {
            display: flex;
            align-self: flex-start;
            height: auto;
        }

        .svg {
            width: auto;
        }

        svg {
            width: $spacing;
            display: block;
            height: 36px;
        }
    }

    &__dropdown-indicator {
        fill: rgba(getColor(black), 1);
    }

    &__clear-indicator {
        fill: rgba(getColor(mid-grey), 1);
    }

    &__menu {
        left: 0;
        right: 0;
        top: 40px;
        z-index: 100;
        font-size: 16px;
        position: absolute;
        border-radius: 3px;
        background-color: rgba(getColor(white), 1);
        box-shadow: 0 0 6px 0 rgba(49, 69, 141, 0.1);

        #{$this}--multi & {
            top: 100%;
        }

        #{$this}--menu-top & {
            top: auto;
            bottom: 100%;
            margin-bottom: $spacing;
        }

        &-list {
            padding: 0px !important;
        }
    }

    &__menu-notice {
        display: block;
        padding: 10px 20px;
    }

    &__option {
        display: block;
        cursor: pointer;
        padding: 10px $spacing 10px 20px;
        transition: getTransition();
        color: rgba(getColor(mid-grey), 1);
        background-color: transparent;
        text-align: left;
        white-space: normal;

        &:hover {
            color: rgba(getColor(dark-grey), 1);
            background-color: rgba(getColor(light-grey), 1);
        }
    }

    &__option--is-focused,
    &__option--is-selected {
        color: rgba(getColor(dark-grey), 1);
        background-color: rgba(getColor(light-grey), 1);
    }

    &--action {
        display: inline-block;
        width: calc(100% - #{$spacing * 7});
        max-width: calc(100% - #{$spacing * 7});
        vertical-align: top;
    }

    &--inline {
        #{$this}__control {
            font: $font-body;

            @include breakpoint(tablet) {
                font: $font-form;
            }
        }
        #{$this}__single-value {
            padding-top: 9px;

            @include breakpoint(tablet) {
                padding: $spacing 46px $spacing 0;
            }
        }
    }

    &--fixed-180 {
        width: 180px;

        @include breakpoint(tablet) {
            width: 100%;
        }
    }
}
