@import 'erpcore/assets/scss/main';

.collapse {
    $_this: &;
    position: relative;
    display: block;
    width: 100%;
    padding-right: $spacing * 5;

    &__toggle {
        position: absolute;
        top: 0;
        right: 0;

        .button__icon svg {
            fill: getColor(dark-anthracite);
        }

        &:hover {
            .button__icon svg {
                // fill: getColor(redlight);
            }
        }

        #{$_this}[aria-expanded='true'] & {
            transform: rotate(180deg);
        }
    }

    &__content {
        display: block;
        width: 100%;

        &-visible {
            display: block;
            width: 100%;
        }

        &-expandable {
            display: none;
            width: 100%;

            #{$_this}[aria-expanded='true'] & {
                display: block;
            }
        }
    }

    .input--required {
        &:after {
            right: -10px;
        }
    }
}
