@import 'erpcore/assets/scss/main';

.faq-items {
    display: block;
    width: 100%;

    @include breakpoint($from: tablet) {
        > .repeater > .repeater__list {
            list-style: none;
            counter-reset: repeater-counter;

            > .repeater__item {
                // border: 5px solid #d2d2d2;
                // box-shadow: inset 0px 0px 10px blue;
                // border-right: 5px solid rgba(#000, 0.3);
                counter-increment: repeater-counter;

                &:nth-child(2n) {
                    // margin-left: -25px;
                    // border-right-color: rgba(#000, 0.1);
                }

                &:before {
                    content: counter(repeater-counter)".";
                    position: absolute;
                    top: 0;
                    right: calc(100% + 10px);
                    font-size: 32px;
                    line-height: 1;
                    font-weight: 700;
                    text-align: right;
                    color: rgba(#000, 0.1);
                }
            }
        }

        .form__columns .form__grid-column:first-child {
            padding-top: 0;
            padding-right: 20px;
            border-right: 1px dashed rgba(getColor(light-grey), 1);
        }
    }
}
