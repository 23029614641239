@import 'erpcore/assets/scss/main';

.prospect-view {
    @include breakpoint($to: tablet) {
        flex-direction: column;
    }

    &__right {
        float: right;
    }

    &__message {
        margin: $spacing * 2 0;
        padding-bottom: $spacing * 2;
        border-bottom: 1px solid rgba(var(--lightGrey), 1);

        .collapse__toggle {
            margin-top: $spacing * 3;
        }

        &:last-of-type {
            padding-bottom: 0;
            border-bottom: none;
        }

        &-header {
            display: flex;
            flex-wrap: nowrap;
            gap: $spacing;
            margin-bottom: $spacing;

            .svg {
                width: 22px;
            }
        }

        &-content {
            margin-top: $spacing * 2;
        }

        &-user {
            margin-bottom: 0;
            font-weight: bold;
            margin-top: 3px;
        }
        &-subject {
            display: block;
            font-weight: bold;
            margin-bottom: $spacing;
        }
        &-time {
            time {
                margin-right: $spacing * 3;
            }
        }
    }

    .svg {
        margin-right: $spacing;
        fill: rgba(getColor(denim), 1);
    }

    &__header {
        margin-bottom: $spacing * 2;
    }

    &__notes {
        overflow-y: auto;
        max-height: 400px;
    }

    &__label {
        display: block;
        font: $font-label;
        margin-top: $spacing;
    }
    &__value {
        display: block;
        margin-bottom: $spacing * 2;

        &-chip {
            background: rgba(getColor(denim), 0.1);
            color: rgba(getColor(denim), 1);
            border-radius: 5px;
            display: inline-block;
            margin: 2px $spacing 2px 0;
            padding: 2px $spacing;
        }

        &--project {
            font: $font-4;
            margin-top: $spacing;
        }
    }

    &__items {
        margin-bottom: $spacing * 4;
    }

    &__session {
        position: relative;
        background: rgba(getColor(white), 1);
        border: 1px solid rgba(getColor(light-grey), 1);
        margin-bottom: $spacing * 2;
        padding: $spacing * 2 $spacing * 2 $spacing $spacing * 2;

        &-questions {
            overflow-y: auto;
            max-height: 400px;
            margin-bottom: $spacing * 2;
            padding-right: $spacing;
        }

        &-document-form {
            margin-top: $spacing * 2;

            &-label {
                display: block;
                margin-bottom: $spacing;
            }
        }

        &-details {
            float: right;
            width: 60%;
            font: $font-body;
            @include breakpoint($to: mobile) {
                width: 100%;
                margin: $spacing 0;
            }

        }

        &-detail {
            margin-left: $spacing * 4;

            &:first-child {
                margin-left: 0;
            }
        }

        &-line {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            border-bottom: 1px solid rgba(getColor(light-grey), 1);
            padding: $spacing 0;

            &:last-child {
                border-bottom: none;
            }

            &-links {
                width: 50%;
                text-align: right;
            }
            &-label {
                display: flex;
                width: 40%;
                .svg {
                    margin-left: $spacing;
                }
                @include breakpoint($to: mobile) {
                    width: 100%;
                }

            }
            &-stats {
                display: flex;
                margin-top: $spacing;
                width: 100%;
                .svg {
                    margin-right: $spacing;
                }
            }
            &-content {
                display: flex;
                align-items: center;
                flex: 100%;

                .svg {
                    margin-right: $spacing;
                }
            }
        }

        .collapse {
            padding-right: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__info-box {
        display: flex;
        justify-content: space-around;
        @include breakpoint($to: tablet) {
            flex-wrap: wrap;
            margin-bottom: -$spacing * 2;
        }
    }

    &__data {
        display: flex;
        flex-direction: column;
        flex: 1 1 calc(100% / 6);
        width: calc(100% / 6);

        @include breakpoint($to: tablet) {
            flex: 1 1 calc(100% / 3);
            padding-bottom: $spacing * 2;
        }
        @include breakpoint($to: mobile) {
            flex: 1 1 50%;
        }

        &-header {
            font: $font-label;
        }

        &-body {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 0;
        }
    }

    &__content {
        display: flex;
        width: 100%;
        padding-bottom: $spacing * 2;

        @include breakpoint($to: tablet) {
            flex-direction: column;
        }
    }

    &__main {
        flex: 3;
        margin-right: $spacing * 3;

        @include breakpoint($to: tablet) {
            margin-right: 0;
            margin-bottom: $spacing * 3;
        }
    }
    &__sidebar {
        flex: 2;

        .collapse {
            padding-right: 0;
            margin-bottom: $spacing * 3;

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }

    &__notes-list {
        padding: 0 0 0 $spacing * 2;
    }
    &__deals {
        padding-bottom: 0;
    }
    &__deal {
        margin: $spacing * 2 0px;
        border-bottom: 1px solid rgba(getColor(light-grey), 1);

        &:last-child {
            border-bottom: none;
        }

        &-header {
            display: flex;
        }
        &-title {
            font: $font-body;
            font-weight: bold;
            width: 100%;
        }
        &-link {
            margin-left: $spacing * 2;
        }
        &-info {
            margin: $spacing 0;
            display: flex;
            gap: $spacing * 2;
            font: $font-list;
        }
    }
}
