@import 'erpcore/assets/scss/main';

.app-statuses {
    display: flex;
    margin: 10px 0 16px 0;

    &-icon {
        margin-right: 10px;
    }

    &-status:not(.app-statuses-status:last-child) {
        margin-right: 10px;
    }

    &-status {
        & .status-circle {
            margin-right: 5px;
        }

        & > span {
            color: rgba(getColor(mid-grey), 1);
        }
    }
}