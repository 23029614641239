@import 'erpcore/assets/scss/main';

.units-import {
    .button {
        margin-bottom: $spacing;
    }

    p {
        font: $font-body;
        margin-bottom: $spacing;

        &:last-of-type {
            margin-bottom: $spacing * 3;
        }
    }
}
