@import 'erpcore/assets/scss/main';

.demo-sessions-widget {
    max-height: 60vh;
    overflow: auto;

    @include breakpoint($to: mobile) {
        max-height: 50vh;
    }

    .widget__content {
        display: block;
    }

    &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__totals {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacing * 3;
        padding-bottom: $spacing * 3;
        border-bottom: 1px solid rgba(getColor('light-grey'), 1);

        &-block {
            flex-basis: 45%;
            max-width: 45%;
            display: flex;
        }

        &-icon {
            height: 65px;
            margin-right: $spacing * 2;

            .isvg,
            svg {
                height: 100%;
                width: auto;
            }
        }

        &-stats {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            h3,
            p {
                margin-bottom: 0;
            }
        }
    }
}
