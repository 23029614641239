@import 'erpcore/assets/scss/main';

.session-report-per-realtor {
    .widget__content {
        display: block;
    }

    &--big {
        font-size: 35px;
        line-height: 35px;
        font-weight: 600px;
    }

    &__chart {
        margin-top: $spacing * 2;
        @include breakpoint($to: mobile) {
            overflow: auto;

            &-responsive {
                width: 800px;
            }
        }
    }

    &__line {
        margin-top: $spacing * 5;
        max-height: 300px;
        padding-right: $spacing;

        overflow-y: auto;

        &-single {
            margin-bottom: $spacing * 3;
            p {
                display: flex;
                justify-content: space-between;
                margin-bottom: 0;

                span {
                    font-weight: 600;
                }
            }
        }
    }
}
