@import 'erpcore/assets/scss/main';

.user-portal {
  &__deal-row {
    display: flex;
    border-bottom: 1px solid #CCCCCC;
    align-items: center;
    padding: $spacing 0;

    & > * {
      margin-right: $spacing * 2;
      &:last-child {
        margin-left: auto;
      }
    }
  }
  &__checkbox {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #CCCCCC;
    background: #fff;
    margin: $spacing;

    &--action {
      cursor: pointer;
    }

    &:before {
      position: absolute;
      content: '';
      display: block;
      width: 12px;
      height: 6px;
      border-left: 2px solid limegreen;
      border-bottom: 4px solid limegreen;
      transform: rotate(-53deg) scale(0);
      top: 2px;left: 2px;
      transform: getTransition();
    }

    &--checked {
      &:before {
        transform: rotate(-53deg) scale(1);
      }
    }
  }
  &__checks {
    display: flex;
    align-items: center;
  }
  &__check {
    display: flex;
    align-items: center;
    margin-right: $spacing * 2;
  }
}