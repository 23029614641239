@import 'erpcore/assets/scss/main';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.oi-access-dashboard {
    &__subscribe {
        transform: scale(1);
        &:hover {
            opacity: .8;
            transform: scale(1.5);
        }
    }
    &__learn-more {
        display: block;
        text-align: center;
        font-size: 52px;
        font-family: 'ButlerRegular';
        font-weight: normal;
        margin-bottom: 6%;
        margin-top: 2%;
        line-height: 1.2;

        @include breakpoint($to: LargeDesktop) {
            font-size: 36px;
        }

        a {
            position: relative;

            &:after {
                display: block;
                position: absolute;
                bottom: $spacing;
                left: 0;
                right: 0;
                content: '';
                border-bottom: 2px solid #000;
            }
        }
    }

    &__footer {
        display: flex;
        flex-wrap: wrap;
        padding: 6% 0;
        border-top: 2px solid #000;
        margin: 0px auto;
        max-width: 1860px;

        &-link {
            font-size: 26px;
            margin-right: $spacing * 8;
            white-space: nowrap;

            @include breakpoint($to: LargeDesktop) {
                font-size: 19px;
                margin-right: $spacing * 3;
            }

            @include breakpoint($to: mobile) {
                width: 100%;
                text-align: center;
                padding: $spacing  0;
                margin: 0;
            }
        }

        &-contact {
            margin-left: auto;
            margin-top: -20px;
            font-size: 28px;
            border: 1px solid #000;
            border-radius: 40px;
            padding: $spacing * 3 $spacing * 5;
            white-space: nowrap;

            @include breakpoint($to: LargeDesktop) {
                font-size: 17px;
                padding: $spacing $spacing * 2;
            }

            @include breakpoint($to: mobile) {
                margin: $spacing * 2 auto 0 auto;
            }

            &:hover {
                background: #000;
                color: #fff;
            }
        }
    }

    &__video {
        position: relative;
        width: 100%;
        aspect-ratio: 16 / 9;

        &-play {
            cursor: pointer;
            position: absolute;
            fill: #fff;
            width: 100px;
            height: 100px;
            margin-left: -50px;
            margin-top: -50px;
            top: 50%;
            left: 50%;
            transform-origin: center;
            transition: getTransition();
            background: transparent;
            border: none;

            &:hover {
                transform: scale(1.1);
            }

            &:active {
                transform: scale(.9);
            }
            .svg {
                width: auto;
            }
            svg {
                width: 100px;
                height: 100px;
            }
        }
    }
    &__iframe {
        width: 100%;
        aspect-ratio: 16 / 9;
    }

    &__title {
        margin: 0px auto;
        position: relative;
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        padding: $spacing*3 0;
        letter-spacing: 3px;
        font-family: 'Montserrat', sans-serif;
        max-width: 1860px;
        line-height: 1.2;

        @include breakpoint($to: mobile) {
            padding: $spacing 0;
        }

        &:after {
            position: absolute;
            content: '';
            left: 50%;
            transform: translateX(-50%);
            display: block;
            height: 12px;
            width: 100%;
            max-width: 435px;
            background: #000;
            bottom: -12px;

            @include breakpoint($to: desktop) {
                width: 200px;
                height: 8px;
                bottom: -8px;
            }
        }
    }

    &__section {
        padding: 6% 0;
        max-width: 1860px;
        margin: 0px auto;

        h1 {
            text-align: center;
            font: $font-1;
            font-size: $spacing * 10;
            margin: 8% 0 6% 0;
            line-height: 1.2;

            @include breakpoint($to: LargeDesktop) {
                font-size: 38px;
            }
        }

        h2 {
            text-align: center;
            font-family: 'Montserrat', sans-serif;
            font-size: $spacing * 4;
            margin: -2% auto 0 auto;
            font-weight: lighter;
            line-height: 1.5;
            max-width: 1450px;
            margin-top: -4%;

            @include breakpoint($to: LargeDesktop) {
                font-size: 21px;
            }

            @include breakpoint($to: mobile) {
                font-size: 18px;
            }
        }
    }

    &__columns {
        column-count: 2;
        column-gap: 50px;
        text-align: justify;

        @include breakpoint($to: mobile) {
            column-count: 1;
        }

        p, span {
            font-family: 'Montserrat', sans-serif !important;
            font-size: 18px !important;
            line-height: 1.5;

            @include breakpoint($to: mobile) {
                font-size: 14px !important;
            }
        }

    }

    &-card {
        width: 200px;
        height: auto;

        &-image {
            width: 100%;
            height: 200px;
        }

        &-description {
            width: 100%;
            height: 100px;
        }
    }

    &-footer {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.page-header-centered {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;

    img {
        max-width: 8rem;
    }
}
