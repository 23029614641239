@import 'erpcore/assets/scss/main';

.sessions-over-time {
    .widget__content {
        flex-direction: column;

        @include breakpoint($to: mobile) {
            overflow: auto;
        }
    }

    .form__column {
        flex-basis: 15% !important;
        width: 15% !important;
    }

    &__chart {
        width: 100%;

        @include breakpoint($to: mobile) {
            overflow: auto;

            &-responsive {
                width: 1000px !important;
            }
        }

        canvas {
            height: 400px !important;
        }
    }
}
