@import 'erpcore/assets/scss/main';

.session-reports {
    &-item {
        .widget-item {
            height: 100%;
            display: flex;
            align-items: center;
        }
        & > .isvg {
            height: 60px;
            width: auto;

            svg {
                height: 100%;
                width: auto;
            }
        }
    }
}
