@import 'erpcore/assets/scss/main';

.gauge-chart {
    display: block;
    height: auto;
    width: 100%;
    position: relative;

    canvas {
        z-index: 11;
        position: relative;
    }
}
