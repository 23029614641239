@import 'erpcore/assets/scss/main';

.card {
    display: block;
    width: 100%;
    padding: $spacing * 3;
    border: 1px solid rgba(getColor(light-grey), 1);
    border-bottom: 1px solid rgba(getColor(mid-grey), 1);
    background: #fafafa;
    border-radius: 3px;

    &__break {
        height: 1px;
        background-color: rgba(getColor(light-grey), 1);
        margin: $spacing * 2 0;
    }

    &__title {
        font: $font-3;
        margin-bottom: $spacing;
    }

    .table-actions__items {
        background: rgb(250, 250, 250);
        background: linear-gradient(90deg, rgba(250, 250, 250, 0) 0%, rgba(250, 250, 250, 1) 15%);
    }

    @include breakpoint(mobile) {
        .table-actions {
            float: none;
            width: auto;
            height: auto;
            cursor: default;

            &__dots {
                display: none;
            }

            &__items {
                top: auto;
                margin: 0;
                opacity: 1;
                padding: 0;
                right: auto;
                width: 100%;
                bottom: auto;
                display: flex;
                position: relative;
                visibility: visible;
                flex-direction: row;
                pointer-events: all;
                justify-content: flex-start;
            }

            &__item {
                padding-left: 0;
                padding-right: $spacing;

                &:empty {
                    display: none;
                }

                &:last-child {
                    padding-right: 0;
                }

                &:last-child:first-child {
                    padding-right: $spacing;
                }

                a,
                button {
                    width: auto;
                    height: auto;
                    cursor: pointer;
                    padding: $spacing;
                    border-radius: 3px;
                    -webkit-appearance: none;
                    border: 1px solid rgba(getColor(mid-grey), 1);
                }

                svg,
                .svg {
                    display: block;
                }
            }
        }
    }
}
