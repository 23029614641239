@import 'erpcore/assets/scss/main';

.user-permissions {

  &__valid-until {
    cursor: pointer;
  }

  &__red {
    color: red;
    button {
      color: red;
    }
  }

  &__appraiser,
  &__client {
    position: relative;

    &-column {
      width: 47.5%;
      float: left;
      margin-right: 5%;

      &:last-of-type {
        margin-right: 0;
      }

      @include breakpoint($to: desktop) {
        width: 100%;
        margin-right: 0;
        margin-bottom: $spacing * 2;
      }
    }

    &-assignment {
      display: block;
      padding: $spacing 0;
      margin: $spacing 0;
      border-bottom: 1px solid #ddd;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      justify-items: center;

      .button {
        margin-left: 8px;
      }

      & > div {
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        margin-left: $spacing;
      }

      &:last-of-type {
        border-bottom: 0;
        margin-bottom: $spacing * 2;
      }
    }
  }
}
