@import 'erpcore/assets/scss/main';

.project-form {
  &__website-builder {
    padding: $spacing * 2;
    border: 1px solid rgba(getColor(light-grey), 1);
    margin-bottom: $spacing * 4;
  }
}

.copy-link {
  display: flex;
  background: transparent;
  cursor: pointer;
  border: transparent;
  padding: $spacing;
  clear: both;
  line-break: anywhere;
  text-align: left;

  &:hover {
    color: rgba(getColor(sky), 1);
  }

  :global {
    .svg {
      margin-right: $spacing;
      fill: rgba(getColor(sky), 1);
    }
  }
}