@import 'erpcore/assets/scss/main';

.prospect-profile-questions {
    &__answer {
        padding: $spacing / 2 $spacing * 1.5;
        background-color: rgba(getColor(denim), 0.15);
        color: rgba(getColor(denim), 1);
        border-radius: 20px;
        margin-right: $spacing / 2;
        float: left;
        margin-bottom: $spacing / 2;
        font: $font-label;

        &:last-of-type {
            margin-right: 0;
        }
    }
}
