@import 'erpcore/assets/scss/main';

.color-picker {
    &__trigger {
        cursor: pointer;
        width: 100%;
        background: transparent;
        height: 40px;
        border: 2px solid rgba(getColor(light-grey), 1);
        padding: $spacing 40px $spacing $spacing;
    }

    &__selected-color {
        width: 100%;
        height: 20px;
        border-radius: 3px;
        color: rgba(getColor(white), 1);
        text-shadow: 1px 1px 1px rgba(getColor(black), 1);
        text-transform: uppercase;
        line-height: 20px;
        text-align: left;
        padding: 0 $spacing;
    }

    &__picker {
        z-index: 999;
        position: absolute;
    }

    &__cover {
        cursor: pointer;
        display: block;
        width: 100%;
        background: transparent;
        border: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
