@import 'erpcore/assets/scss/main';

.demo-session-schedule {
    margin-bottom: $spacing * 3;
    h4 {
        margin-bottom: $spacing * 2;
    }
    &__list {
        &-item {
            margin-bottom: $spacing;
            display: flex;
            justify-content: space-between;

            span {
                display: inline-block;
                margin-right: $spacing * 2;
                font: $font-body;

                &:last-of-type {
                    margin-right: 0;
                }
            }

            &-data {
                flex-grow: 1;
            }
            &-link {
                margin-left: $spacing * 2;
                flex-basis: 100px;
                max-width: 100px;
                min-width: 100px;
            }
        }
    }
}
