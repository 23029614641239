@import 'erpcore/assets/scss/main';

.integration-hubspot-button {
    background-color: #f8761f;

    &:hover {
        background-color: #efa26f;
    }
}

.integration-avesdo-button {
    background-color: #00afb5;

    &:hover {
        background-color: #44cccc;
    }
}