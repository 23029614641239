@import 'erpcore/assets/scss/main';

.repeater--session-prospects {

    &-error-to-many {
        .repeater__item:not(:first-child) {
            border-color: rgba(getColor(tomato), 1);
        }
    }
}

.session-repeatable-item {
    display: flex;

    @include breakpoint($to: tablet) {
        flex-wrap: wrap;
    }

    &__title {
        flex: 0 0 25%;
        margin: 0 $spacing $spacing 0;
        font: $font-4;

        @include breakpoint($to: tablet) {
            flex: 0 0 100%;
        }
    }

    &__data {
        flex: 0 0 75%;

        @include breakpoint($to: tablet) {
            flex: 0 0 100%;
        }

        &-item {
            margin: 0 0 $spacing;

            &:last-child {
                margin-bottom: 0;
            }

            &-title {
                margin: 0 0 -4px;
                font: $font-body;
            }

            &-link {
                font: $font-label;
                word-break: break-all;
            }
        }
    }
}
