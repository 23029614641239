@import 'erpcore/assets/scss/main';

.oi-access-sign-in {
    display: flex;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    background: url('../../assets/images/mla-oi-access-logo-black.png') center center / 97%
        no-repeat padding-box content-box gainsboro;

    &__form {
        padding: 2rem 4rem;
        background: #fff;
    }
}
