@import 'erpcore/assets/scss/main';

.gallery {
    $this: &;
    position: relative;
    display: block;
    width: 100%;
    min-height: 104px;

    &--dragging {
        cursor: move;
    }

    &--busy {
    }

    &__loader {
        position: absolute;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 2;
        background: rgba(#fff, 0.5);

        .element-loader {
            margin-left: 16px;
            margin-top: 16px;
        }
    }

    &__grid {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
    }

    &__info {
        padding: $spacing 0;
        color: rgba(getColor(mid-grey), 1);
        font-size: $font-list;
    }

    &__item {
        position: relative;
        width: 27.83%;
        min-width: 100px;
        max-width: 120px;
        min-height: 100px;
        max-height: 120px;
        margin-right: $spacing * 2;
        margin-bottom: $spacing * 2;
        background: rgba(getColor(white), 1);

        @include breakpoint(small) {
            margin-right: $spacing;
            margin-bottom: $spacing;
        }

        &--dragged {
            box-shadow: 0px 0px 0px 2px rgba(getColor(white), 1);

            #{$this}__item-move {
                display: block;
            }
        }

        &:after {
            content: ' ';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            pointer-events: none;
            transition: border 0.3s;
        }

        &--not-image {
            &:after {
                border-color: transparent;
            }
        }

        &:hover {
            &:after {
                // border-color: rgba(getColor(grey), 1);
            }
        }

        &--featured {
            &:after {
                border: 2px solid rgba(getColor(denim), 1);
            }
        }

        &-move {
            display: none;
            position: absolute;
            top: $spacing;
            left: $spacing;
            width: 16px;
            height: 16px;

            &-icon {
                display: block;
                width: 16px;
                height: 16px;
                fill: rgba(getColor(white), 1);
            }
        }

        &-inner {
            position: relative;
            display: block;
            width: 100%;
            padding-bottom: 100%;
            // border-radius: 50%;
            overflow: hidden;
        }

        &-content {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            &:before {
                content: ' ';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                background: #000;
                opacity: 0;
                transition: opacity 0.3s;
                pointer-events: none;
                z-index: 2;

                #{$this}__item:hover & {
                    opacity: 0.5;
                }
            }

            #{$this}__item--not-image & {
                &:after {
                    content: ' ';
                    position: absolute;
                    display: block;
                    bottom: 0;
                    right: 0;
                    width: 0;
                    height: 0;
                    border-width: 10px;
                    border-style: solid;
                    border-color: transparent #fff #fff transparent;
                    z-index: 2;
                }
            }
        }

        &-information {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            display: block;
            width: 100%;
            max-height: calc(100% - 28px);
            padding: $spacing $spacing 0;
            color: rgba(getColor(white), 1);
            text-align: left;
            font-size: 10px;
            line-height: 12px;
            overflow: hidden;
            opacity: 1;
            z-index: 1;
            transition: getTransition();

            #{$this}__item--active &,
            #{$this}__item:hover &,
            #{$this}__item--dragged & {
                opacity: 0;
                pointer-events: none;
            }

            .tooltip {
                max-width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &-item {
                display: block;
                margin: 0 0 4px;
                word-break: break-word;

                &:last-child {
                    margin-bottom: 0;
                }

                &--small {
                    font-size: 10px;
                    opacity: 0.8;
                }
            }
        }

        // css icon
        &-file-type {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            padding: $spacing/2 $spacing;
            margin: 0 auto;
            font-weight: 400;
            text-transform: lowercase;
            font-size: 13px;
            line-height: 14px;
            letter-spacing: 0.5px;
            text-align: left;
            border-radius: 3px;
            background: rgba(getColor(mid-grey), 1);
            color: rgba(#fff, 0.8);

            &[data-file-type='pdf'] {
                background: #c03630;
            }

            &[data-file-type='zip'],
            &[data-file-type='zipx'],
            &[data-file-type='rar'],
            &[data-file-type='tar'],
            &[data-file-type='7z'] {
                background: #d78e0f;
            }

            &[data-file-type='csv'],
            &[data-file-type='xls'],
            &[data-file-type='xlsx'],
            &[data-file-type='xlsm'],
            &[data-file-type='xlsb'],
            &[data-file-type='ods'] {
                background: #217346;
            }

            &[data-file-type='ppt'],
            &[data-file-type='pptx'],
            &[data-file-type='pptm'] {
                background: #b7472a;
            }

            &[data-file-type='doc'],
            &[data-file-type='docx'],
            &[data-file-type='docm'],
            &[data-file-type='odt'] {
                background: #2b579a;
            }

            &:before {
                content: ' ';
                position: absolute;
                display: block;
                bottom: 0;
                right: 0;
                width: 0;
                height: 0;
                border-top-left-radius: 2px;
                border-width: 10px;
                border-style: solid;
                border-color: rgba(#fff, 0.35) #fff #fff rgba(#fff, 0.35);
            }

            &:after {
                display: block;
                content: attr(data-file-type);
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 4px 6px;
                word-break: break-all;
                overflow: hidden;
                box-sizing: inherit;
            }
        }

        &-actions {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            opacity: 0;
            transition: opacity 0.3s, visibility 0.3s;
            pointer-events: none;
            visibility: hidden;
            z-index: 3;

            #{$this}__item:hover & {
                opacity: 1;
                transition-delay: 0.1s;
                animation: delay-pointer-events 0.1s linear;
                pointer-events: auto;
                visibility: visible;
            }

            &:empty,
            #{$this}--dragging & {
                pointer-events: none !important;
                opacity: 0 !important;
            }
        }

        &-action {
            position: relative;
            display: block;
            flex: 0 1 auto;
            align-items: center;
            max-width: 100%;
            margin: 0;
            padding: $spacing;
            font: $font-2;
            border: none;
            border-radius: 50%;
            text-transform: none;
            text-align: center;
            text-decoration: none;
            background-image: none;
            background-color: transparent;
            vertical-align: middle;
            user-select: none;
            touch-action: manipulation;
            cursor: pointer;
            white-space: nowrap;
            transition: getTransition();

            &--active,
            &:hover {
                background-color: rgba(getColor(denim), 1);
            }

            &-icon {
                display: block;
                width: 16px;
                height: 16px;

                svg {
                    display: block;
                    width: 16px;
                    height: 16px;
                    fill: rgba(getColor(white), 1);
                }
            }
        }

        &-progress {
            $progress: &;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;

            &-bar {
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: rgba(getColor(light-grey), 0.9);
                transition: height 0.2s;
            }

            &-loader {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
            }

            &-value {
                position: absolute;
                bottom: $spacing;
                right: $spacing;
                display: block;
                width: $spacing * 5;
                height: $spacing * 5;
                font: $font-5;
                line-height: $spacing * 5;
                text-align: center;
                color: rgba(getColor(white), 1);
                background: rgba(getColor(apple), 1);
                border-radius: 100%;
                white-space: nowrap;
                transition: getTransition();

                #{$progress}--fail & {
                    background: rgba(getColor(denim), 1);
                }

                &-icon {
                    display: block;
                    margin: 12px auto 0;
                    width: $spacing * 2;
                    height: $spacing * 2;

                    svg {
                        display: block;
                        width: $spacing * 2;
                        height: $spacing * 2;
                        fill: rgba(getColor(white), 1);
                    }
                }
            }
        }
    }

    &__upload {
        display: flex;
        justify-content: center;
        align-items: stretch;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        border: 2px solid rgba(getColor(grey), 1);
        // border-radius: 50%;
        transition: getTransition();
        background: rgba(#fff, 0.9);
        z-index: 1;

        &-receiver {
            position: absolute;
            display: block;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
        }

        &--drag-entered {
            border: 2px dashed rgba(getColor(denim), 1);
        }

        &-button {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            cursor: pointer;

            &-icon {
                display: block;
                position: relative;
                font: $font-2;
                transform: scale(1);
                transition: getTransition();

                #{$this}__upload--drag-entered &,
                #{$this}__upload:hover &,
                #{$this}__upload:hover &,
                #{$this}__upload:active &,
                #{$this}__upload:focus & {
                    color: rgba(getColor(denim), 1);
                }
            }
        }

        input {
            display: none;
        }
    }
}

.page-my-account .gallery__item-inner {
    border-radius: 50%;
}

.page-my-account .gallery__upload {
    border: 2px solid rgba(getColor(grey), 1);
    border-radius: 50%;
}

@keyframes delay-pointer-events {
    0% {
        visibility: hidden;
    }
    50% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}
