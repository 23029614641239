@import 'erpcore/assets/scss/main';

.switch-card {
    $this: &;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    text-align: left;
    border-radius: 3px;
    border: 2px solid rgba(getColor(light-grey), 1);

    &__content {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 100%;
        text-align: left;
        padding: $spacing * 2;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }

    &__title {
        width: calc(100% - #{$spacing * 5});
        max-width: calc(100% - #{$spacing * 5});
        min-width: calc(100% - #{$spacing * 5});
        font: $font-4;
        margin: 0;
    }

    &__description {
        width: 100%;
        min-width: 100%;
        margin: $spacing * 2 0 0;

        & a {
            pointer-events: all;
        }
    }

    &__action {
        width: $spacing * 4;
        min-width: $spacing * 4;
        margin-left: $spacing;
    }

    &__input {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: flex-start;
        margin: 0;

        &-error {
            font: $font-label;
            width: 100%;
            margin-left: $spacing * 2;
            margin-top: $spacing;
            color: rgba(getColor(tomato), 1);
        }

        &-toggle {
            width: 32px;
            height: 16px;
            display: block;
            font-size: 13px;
            cursor: pointer;
            border-radius: 8px;
            position: relative;
            background-color: white;
            transition: getTransition();
            border: 2px solid rgba(getColor(light-grey), 1);

            &:before {
                top: -2px;
                left: -2px;
                z-index: 2;
                width: 16px;
                height: 16px;
                content: '\A';
                position: absolute;
                border-radius: 100%;
                transform: translateX(0px);
                transition: getTransition();
                background-color: rgba(getColor(mid-grey), 1);
            }
        }

        &-toggle--active {
            &:before {
                transform: translateX(16px);
            }
        }

        &-toggle--green {
            border: 2px solid rgba(getColor(apple), 1);
            background-color: rgba(getColor(apple), 1);
        }

        &-checkbox {
            display: none;
        }
    }

    &__children {
        width: 100%;
        padding: 0 ($spacing * 2) ($spacing * 2) ($spacing * 2);
    }
}
