@import 'erpcore/assets/scss/main';

.units {
    &__add-and-import {
        margin-bottom: $spacing * 4;
        display: flex;
        justify-content: space-between;

        &-title {
            flex-grow: 1;
            margin-right: $spacing * 2;
            h3 {
                margin-bottom: $spacing * 1.5;
            }
            p {
                font: $font-label;
            }
        }

        &-buttons {
            display: flex;
            align-items: center;
        }
    }
}
