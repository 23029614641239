@import 'erpcore/assets/scss/main';

.oi-access-help {
  margin: 0px auto;
  max-width: 640px;

  &__title {
    text-align: center;
    margin: $spacing *  3 auto;
  }
}