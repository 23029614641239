@import 'erpcore/assets/scss/main';

.tags {
    position: relative;
    margin-bottom: $spacing * 2;

    .input__field {
        height: auto;
        min-height: 40px;
    }

    &-helper {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate3d(0, 120%, 0);
        font: $font-label;
    }

    .input__label {
        font-size: 11px;
        background: -webkit-gradient(
            linear,
            left bottom,
            left top,
            from(white),
            color-stop(50%, white),
            color-stop(51%, rgba(255, 255, 255, 0)),
            to(rgba(255, 255, 255, 0))
        );
        background: linear-gradient(
            0deg,
            white 0%,
            white 50%,
            rgba(255, 255, 255, 0) 51%,
            rgba(255, 255, 255, 0) 100%
        );
        -webkit-transform: translate(15px, -11px);
        transform: translate(15px, -11px);
    }

    &__input {
        height: 100%;
        display: flex;
        flex-wrap: wrap;

        input {
            padding-left: $spacing;
        }

        &-remove {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25px;
            background-color: transparent;
            border: 0;
            height: 100%;
            transition: getTransition();
            cursor: pointer;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;


            &:hover {
                svg {
                    fill: rgba(getColor(tomato), 1);
                }
            }

            span {
                display: flex;
                justify-content: center;
            }

            svg {
                width: 8px;
                height: 8px;
                display: block;
                fill: rgba(getColor(mid-grey), 1);
            }
        }

        &-add {
            height: 20px;
            align-self: center;
            margin-top: 1px;
            top: 0;
            margin: #{$spacing + 1px} $spacing $spacing 0; // no bottom margin  margin: 1px;
            position: relative;
            flex-grow: 1;
            border: unset;
        }

        &-title {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 20px;
            max-width: 100%;
            margin: #{$spacing + 1px} $spacing $spacing 0; // no bottom margin
            padding: 0 $spacing * 4 0 $spacing * 2;
            font: $font-label;
            color: rgba(getColor(mid-grey), 1);
            background: rgba(getColor(light-grey),1 );
            border-radius:20px;
        }
    }
}
