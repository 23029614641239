@import 'erpcore/assets/scss/main';

.oi-access-project {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;

    @include breakpoint($to: tablet) {
        margin-top: 50px;
    }

    img {
        max-width: 8rem;
    }

    &__heading {
        display: flex;
        flex-direction: column;
        margin: 24px 0 16px 0;

        h2 {
            text-transform: uppercase;
        }
        p {
            margin-left: $spacing * 3;
        }
    }

    &__message {
        margin-top: 4rem;
    }

    &__iframe {
        position: relative;
        padding: 0px;
        width: 100%;
        min-height: 75vh;
        box-shadow: 0 0 3px rgba(0, 0, 0, .1);

        &--overlay {
            &:after {
                pointer-events: none;
                content: '';
                z-index: 2;
                background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 42%, rgba(255,255,255,0) 44%, rgba(255,255,255,0) 55%, rgba(255,255,255,1) 57%, rgba(255,255,255,1) 100%);
                left: 0;
                right: 0;
                position: absolute;
                bottom: 0;
                height: 65px;

                @include breakpoint($to: tablet) {
                    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,0) 37%, rgba(255,255,255,0) 62%, rgba(255,255,255,1) 64%, rgba(255,255,255,1) 100%);
                }

                @include breakpoint($to: small) {
                    background: #eaeaea;
                    right: 150px;
                    height: 40px;
                    pointer-events: all;
                }
            }
        }

        &-wrapper {
            width: 100%;
            position: relative;
        }

        &-overlays {
            @include breakpoint($from: small) {
                &:before {
                    content: '';
                    z-index: 1;
                    background: white;
                    left: 0;
                    width: 30%;
                    position: absolute;
                    bottom: 0;
                    height: 65px;
                }
                &:after {
                    content: '';
                    z-index: 1;
                    background: white;
                    right: 0;
                    width: 30%;
                    position: absolute;
                    bottom: 0;
                    height: 65px;
                }
            }
        }

        :global {
            iframe {
                width: 100%;
                height: 99.6vh;

                @include breakpoint($to: tablet) {
                    height: calc(100vh - 50px);
                }
            }
        }
    }
}
